.social {
  &-post {
    position: relative;
    padding: 15px 12px 15px;
    margin-bottom: 20px;
    border-radius: var(--component-radius);
    box-shadow: var(--component-shadow-secondary);

    @media only screen and (max-width: 867px) {
      width: 98%;
      box-sizing: border-box;
    }
  }

  &-header {
    position: relative;
    width: 100%;

    &-icon {
      width: 50px;
    }

    &-label {
      width: calc(100% - 50px);
      padding-left: 10px;
      font-size: 13px;
      color: var(--dark-grey);

      & span {
        display: block;
        font-size: 11px;
      }
    }
  }

  &-text {
    font-size: 12px;
    line-height: 22px;
    color: var(--dark-grey);
    padding: 15px 0 25px;
    word-break: break-word;

    @media only screen and (max-width: 1967px) {
      font-size: 12px;
    }
  }

  &-date {
    font-size: 10px;
    color: var(--dark-grey);

    & img {
      vertical-align: middle;
      width: 12px;
      margin-right: 8px;
    }
  }
}

.news-content.primary {
  font-weight: 600;
  margin-bottom: 5px;
}

.video {
  &__text {
    margin: 10px 0 0;
  }

  &__btn {
    margin-top: 0;
    font-weight: 600;
  }
}
