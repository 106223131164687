.mentions.keywords-chart {
  @media only screen and (max-width: 1367px) {
    width: calc((100% - 15px) / 2);
    margin-left: 15px;
  }

  @media only screen and (max-width: 1000px) {
    width: 100%;
    box-sizing: border-box;
    margin: 15px 0 0;
    min-height: auto;
  }
}
