.mentions {
  display: inline-block;
  width: calc((100% - 25px) / 2);

  @media only screen and (max-width: 1367px) {
    .dashboard-line:nth-of-type(4) & {
      width: 100%;
      box-sizing: border-box;
    }
  }

  &.right-mentions {
    margin-left: 25px;

    @media only screen and (max-width: 1367px) {
      .dashboard-line:nth-of-type(4) & {
        width: 100%;
        box-sizing: border-box;
      }
    }
  }
}
