.dashboard-line:nth-of-type(4) .title-top {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 550px) {
    align-items: start;
  }
}

.tt {
  &-right {
    margin: 0;
    text-align: right;

    @media only screen and (max-width: 867px) {
      vertical-align: top;
    }

    @media only screen and (max-width: 550px) {
      display: flex !important;
      flex-direction: column;
    }

    &-total {
      box-sizing: border-box;
      padding: 0 25px;
      color: var(--dark-grey);
      cursor: pointer;

      &:hover .tooltip-window {
        display: block;
      }

      @media only screen and (max-width: 1567px) {
        padding: 0 15px;
      }

      @media only screen and (max-width: 550px) {
        padding: 0 0 0 15px;
      }

      &.border-left {
        border-left: 1px var(--accent-grey) solid;

        @media only screen and (max-width: 550px) {
          border: none;
        }
      }
    }
  }

  &-left {
    margin: 0;

    @media only screen and (max-width: 867px) {
      vertical-align: top;
    }

    & .chart-title {
      @media only screen and (max-width: 867px) {
        padding: 10px 0;
      }
    }
  }
}

.ttr {
  &-top {
    font-size: 12px;

    @media only screen and (max-width: 867px) {
      font-size: 11px;
      text-align: left;
    }
  }

  &-bottom {
    font-size: 27px;
    font-weight: 700;
    text-overflow: ellipsis;

    @media only screen and (max-width: 867px) {
      font-size: 16px;
      text-align: left;
    }
  }
}

.mentions.engagements-chart {
  @media only screen and (max-width: 1367px) {
    margin: 15px 0 0 0;
  }
}
