.elections {
  background: var(--lighter-grey);
  padding: 0 50px 50px;
  border-radius: var(--component-radius);
  box-shadow: var(--component-shadow);
  position: relative;
  overflow: hidden;
  min-height: calc(100vh - 130px);
  text-align: center;

  @media only screen and (max-width: 1440px) {
    min-height: calc(100vh - 120px);
  }

  @media only screen and (max-width: 867px) {
    padding: 0 10px 10px;
    min-height: calc(100vh - 110px);
  }

  & .dashboard-title {
    text-align: left;
  }

  & .table {
    margin-bottom: 0;

    & th {
      background-color: var(--lighter-grey);
      text-align: center;
    }

    & td {
      text-align: right;

      &:nth-of-type(1),
      &:nth-of-type(2) {
        text-align: left;

        &.swing {
          text-align: right;
        }
      }
    }

    & .total td {
      text-align: right;
    }
  }

  & .pfr-header {
    @media only screen and (max-width: 867px) {
      padding-left: 0;
    }
  }

  & .fader {
    margin-top: 20px;
  }
}

.info-cell {
  position: relative;

  &.swing {
    padding-right: calc(0.5rem + 25px);

    @media only screen and (max-width: 1100px) {
      padding-right: calc(0.5rem + 15px);
    }

    & .info-box {
      top: auto;
      bottom: 40px;
    }
  }

  & .info-icon {
    margin-top: 5px;
    right: 5px;

    @media only screen and (max-width: 1100px) {
      height: 15px;
      width: 15px;
      line-height: 15px;
      margin-top: 3px;
      right: 3px;
    }
  }

  & .info-box {
    top: 30px;
    right: 0;
  }

  & .iic-exp {
    font-weight: 400;
    text-align: left;
  }
}

.party {
  &__name {
    position: relative;
    padding-left: calc(30px + 0.5rem) !important;
  }

  &__color {
    width: 30px;
    height: 100%;
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.bold {
  font-weight: 600;
}
