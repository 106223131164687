.mentions.shared-links {
  @media only screen and (max-width: 1367px) {
    width: calc((100% - 15px) / 2);
  }

  @media only screen and (max-width: 1000px) {
    width: 100%;
    box-sizing: border-box;
  }
}
