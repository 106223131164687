.dropdown {
  font-size: 12px;
  margin: 0;
  position: relative;

  &__active {
    color: var(--blue-t3);
    font-weight: 600;
    display: flex;
    align-items: center;

    & .navigation__arrow {
      opacity: 1;
    }
  }

  &.pointer {
    cursor: pointer;
  }

  & .options {
    &-wrapper {
      padding: 5px 0;
      height: 220px;

      &.n2 {
        height: 70px;
      }

      &.n3 {
        height: 100px;
      }

      &.n4 {
        height: 130px;
      }

      &.n5 {
        height: 160px;
      }

      &.n6 {
        height: 190px;
      }
    }

    &-list li {
      font-size: 12px;
      padding: 3px 15px 3px 5px;

      &.active {
        background-color: #2371de20;
      }
    }
  }
}
