.data {
  .interest &-row {
    cursor: pointer;
  }

  &-link {
    text-align: left;
    padding: 15px 10px;
    border-bottom: 1px var(--light-grey) solid;
    transition: 400ms;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .data-expanded & {
      padding-left: 40px;
    }

    &.open {
      box-shadow: var(--component-shadow-hover);
      position: relative;
      z-index: 5;
    }

    &.no-data:hover {
      color: var(--dark-grey);
    }

    &:hover {
      background: var(--lighter-grey);
      color: var(--blue-t4);

      .data-expanded & {
        background: #e9e9e98c;
      }

      & img {
        opacity: 0.75;
      }
    }

    &:last-child {
      border-bottom: 0;
    }

    & img {
      transition: 400ms;
    }

    &-left {
      margin: 0;
      width: calc(100% - 70px);

      &:hover {
        & span {
          display: none;

          &.list-number {
            display: inline-block;
          }
        }

        & input {
          cursor: pointer;
          display: inline-block;
          color: var(--blue-t4);

          .interest & {
            font-size: 14px;

            @media only screen and (max-width: 1024px) {
              font-size: 12px;
            }
          }
        }
      }

      & img {
        opacity: 0.5;
        width: 16px;
        margin-right: 15px;
        vertical-align: middle;

        @media only screen and (max-width: 1667px) {
          margin-right: 10px;
        }

        &.sm {
          width: 8px;
        }
      }

      & span {
        font-size: 12px;

        @media only screen and (max-width: 1024px) {
          font-size: 11px;
        }

        .interest & {
          font-size: 14px;

          @media only screen and (max-width: 1024px) {
            font-size: 12px;
          }
        }

        &.list-number {
          display: inline-block;
          box-sizing: border-box;
          line-height: 18px;
          text-align: center;
          font-weight: 700;
          margin-right: 15px;
          font-size: 10px;
          color: var(--blue-t4);
          box-sizing: border-box;
          border-radius: 50%;
          width: 16px;
          height: 18px;
          background: var(--light-grey);
        }
      }

      & input {
        display: none;
        font-size: 12px;
        width: calc(100% - 51px);
        background: transparent;
        border: none;
        cursor: pointer;
        caret-color: transparent;

        @media only screen and (max-width: 1024px) {
          font-size: 11px;
        }
      }
    }

    & &-icon {
      font-size: 10px;
      color: var(--dark-grey);
      text-align: right;
      margin: 0;
      display: flex;
      align-items: center;

      & span {
        margin-right: 10px;
      }

      & img {
        cursor: pointer;
        width: 15px;
        opacity: 0.5;
        vertical-align: middle;
      }
    }
  }

  &-expanded {
    background-color: var(--lighter-grey);
  }
}

.progressbar {
  height: 100%;
  background-color: var(--blue-t3);
  transition: width 0.3s ease;
  border-radius: 4px;
  margin: 0;

  &-container {
    width: 100px;
    height: 8px;
    background-color: #e0e0e0;
    border-radius: 4px;
    overflow: hidden;
    display: inline-block;
    margin-left: 8px;
  }
}

.toggle {
  width: 10px;
  height: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  margin: 0 10px 0 0;
}

.line {
  position: absolute;
  background-color: var(--dark-grey);
  transition:
    transform 0.3s ease,
    opacity 0.3s ease;

  &.horizontal {
    width: 10px;
    height: 2px;
  }

  &.vertical {
    width: 2px;
    height: 10px;

    .toggle.active & {
      transform: rotate(90deg);
      opacity: 0;
    }
  }
}
