.overall-sentiment {
  width: calc((100% - 25px) / 2);
  margin: 0 0 0 25px;
  min-height: 290px;
  padding-bottom: 0;

  @media only screen and (max-width: 1967px) {
    width: calc(33% - 25px);
  }

  @media only screen and (max-width: 1567px) {
    width: calc(35% - 25px);
  }

  @media only screen and (max-width: 1367px) {
    margin-left: 15px;
    width: calc(35% - 15px);
  }

  @media only screen and (max-width: 1120px) {
    width: 100%;
    box-sizing: border-box;
    margin: 15px 0 0;
  }

  @media only screen and (max-width: 867px) {
    min-height: 100px;
  }

  & .sentiment-charts {
    margin-top: 30px;

    @media only screen and (max-width: 1120px) {
      margin-top: 0;
    }
  }
}

.chart-percentages {
  position: relative;
  width: 300px;
  text-align: center;
  margin-top: -75px;

  @media only screen and (max-width: 1967px) {
    width: 220px;
  }

  @media only screen and (max-width: 1496px) {
    width: 200px;
  }

  @media only screen and (max-width: 1120px) {
    width: 250px;
    padding-bottom: 25px;
  }
}

.cp {
  &-negative,
  &-positive {
    width: 50%;
    font-size: 22px;
    font-weight: 600;

    @media only screen and (max-width: 1967px) {
      font-size: 16px;
    }

    @media only screen and (max-width: 1496px) {
      font-size: 12px;
    }

    @media only screen and (max-width: 1120px) {
      font-size: 18px;
    }
  }

  &-negative {
    text-align: left;
    color: var(--red-t4);
  }

  &-positive {
    text-align: right;
    color: var(--green-t5);
  }
}
