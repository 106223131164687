.ward-label {
  background-color: transparent;
  border: none;
  color: #2f4f4f;
  font-size: 14px;
  font-weight: bold;
  padding: 0;
  box-shadow: none;
  white-space: nowrap;
}

.leaflet-bottom {
  display: none;
}
