.login-wrap.faqs {
  background: rgba(0, 0, 0, 0.4);
}

.faq-box {
  width: 72%;
  height: 80%;
  text-align: left;
  padding: 60px 10px 10px 60px;

  @media only screen and (max-width: 867px) {
    padding: 50px 40px 25px 40px;
  }
}

.modal-title {
  font-size: 32px;
  font-weight: 300;

  & img {
    display: inline-block;
    width: 20px;
    opacity: 0.45;
    margin-right: 15px;
  }
}

.guide-title {
  font-weight: 300;
  font-size: 23px;
  padding: 30px 0 7px;
  margin-top: 30px;
  border-top: 1px var(--light-grey) solid;

  &:first-of-type {
    padding-top: 0;
    margin-top: 0;
    border: none;
  }
}

.sub-title {
  display: block;
  font-size: 18px;
  font-weight: 500;
  margin: 25px 0 10px;
}

ol {
  margin: 0;

  & li {
    margin: 7px 0 0 20px;
  }
}

.submit.faq {
  box-sizing: border-box;
  padding: 15px 15px 0 0;
}

.scroll {
  &-wrap {
    height: calc(100% - 175px);
    box-sizing: border-box;
    margin-top: 35px;
  }

  &-content {
    padding-right: 20px;
  }
}
