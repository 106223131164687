.interest {
  width: 100%;

  &.chart-component {
    min-height: 150px;
  }

  & .info-box {
    width: 400px;
  }

  & .iic-sub {
    padding-top: 0;
  }

  & .iic-exp:nth-of-type(3) {
    margin-top: 10px;
  }

  & .no-data {
    text-align: left;
  }
}
