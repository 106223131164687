.top-posts {
  width: calc((100% - 25px) / 2);
  display: inline-block;
  height: 80vh;
  padding: 15px 0;

  @media only screen and (max-width: 1367px) {
    width: calc((100% - 15px) / 2);

    &:nth-child(2) {
      margin-left: 15px;
    }
  }

  @media only screen and (max-width: 1120px) {
    width: 100%;
    height: auto;
    box-sizing: border-box;
  }

  @media only screen and (max-width: 867px) {
    padding: 15px 0;
  }

  & .chart-title {
    padding: 15px 35px;

    @media only screen and (max-width: 1967px) {
      padding: 15px 25px;
    }

    @media only screen and (max-width: 867px) {
      padding: 15px;
    }
  }

  & .sentiment-source-bar {
    margin: 15px 35px;
    width: calc(100% - 70px);

    @media only screen and (max-width: 1967px) {
      margin: 15px 25px;
      width: calc(100% - 50px);
    }

    @media only screen and (max-width: 867px) {
      margin: 15px;
      width: calc(100% - 30px);
    }
  }

  &-list {
    box-sizing: border-box;
    height: calc(100% - 150px);
    padding-right: 10px;

    @media only screen and (max-width: 1967px) {
      padding-right: 5px;
    }

    @media only screen and (max-width: 1120px) {
      height: 500px;
    }

    @media only screen and (max-width: 867px) {
      padding-right: 2px;
    }
  }

  &-wrap {
    padding: 15px 25px 0 35px;

    @media only screen and (max-width: 1967px) {
      padding: 15px 20px 0 25px;
    }

    @media only screen and (max-width: 867px) {
      padding: 15px 13px 0 15px;
    }
  }
}

.no-data {
  font-size: 13px;
  color: var(--dark-grey);
  padding-left: 35px;

  @media only screen and (max-width: 1967px) {
    padding-left: 25px;
  }

  @media only screen and (max-width: 867px) {
    padding-left: 15px;
  }
}
