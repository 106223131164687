.sentiment-source {
  padding-bottom: 25px;
  width: calc((100% - 25px) / 2);

  @media only screen and (max-width: 1967px) {
    width: 67%;
    margin: 0;
  }

  @media only screen and (max-width: 1567px) {
    width: 65%;
  }

  @media only screen and (max-width: 1120px) {
    width: 100%;
    box-sizing: border-box;
  }

  &-bar {
    margin: 15px auto;
    width: 100%;
    border-radius: var(--component-radius);
    background: var(--light-grey);
    overflow: hidden;
    display: flex;
  }
}

.sentiment-source-bar .source-item {
  display: flex;
  width: 20%;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 12px;
  background: var(--light-grey);
  border-left: 1px var(--lighter-grey) solid;
  transition: 400ms;

  @media only screen and (max-width: 1367px) {
    font-size: 10px;
    padding-left: 0;
    padding-right: 0;
  }

  &:hover {
    background: var(--accent-grey);
    cursor: pointer;
  }

  &.selected {
    background: var(--blue-t3);
    color: var(--white);
    border-left: 1px var(--blue-t5) solid;
    border-right: 1px var(--blue-t5) solid;
    transition: 400ms;

    &:hover {
      background: var(--blue-t4);
      color: var(--white);
      border-left: 1px var(--blue-t5) solid;
      border-right: 1px var(--blue-t5) solid;
    }
  }
}

.info {
  &-icon {
    position: absolute;
    right: 35px;
    top: 0;
    margin: 15px auto 0;
    font-size: 10px;
    font-weight: 300;
    border-radius: 50%;
    background: var(--accent-grey);
    width: 20px;
    height: 20px;
    display: block;
    line-height: 20px;
    box-sizing: border-box;
    text-align: center;
    transition: 400ms;

    @media only screen and (max-width: 1967px) {
      right: 25px;
    }

    @media only screen and (max-width: 867px) {
      right: 15px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &-box {
    position: absolute;
    right: -20px;
    top: 40px;
    padding: 15px 25px;
    background: var(--white);
    border-radius: var(--component-radius-secondary);
    box-shadow: var(--component-shadow-secondary);
    width: 250px;
    height: auto;
    z-index: 27;

    &.hidden {
      display: none;
    }

    .info-icon:hover + & {
      display: block;
      opacity: 1;
    }

    & ol {
      padding-left: 0;
    }
  }
}

.ii {
  &-title,
  &c-sub {
    font-size: 16px;
    font-weight: 400;
    padding: 20px 0 10px;
  }

  &-copy {
    font-size: 12px;

    & ol li {
      padding: 5px 0;
    }
  }
}

.link {
  color: var(--blue-t3);
  cursor: pointer;
  transition: 400ms;
  margin-top: 15px;
  display: inline-block;

  &:hover {
    color: var(--blue-t4);
  }
}
