.summary {
  &.chart-component {
    width: 100%;
    min-height: auto;
    padding-bottom: 30px;
  }

  &__list {
    padding-left: 1rem;
  }

  &__item {
    margin-bottom: 0.5rem;
  }

  & .no-data {
    padding: 0;
  }

  & .chart-title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media only screen and (max-width: 550px) {
      flex-direction: column;
      align-items: start;
    }
  }

  & .navigation__arrow {
    width: 8px;
  }
}
