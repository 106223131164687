.marketpulse {
  background: var(--lighter-grey);
  padding: 30px;
  border-radius: var(--component-radius);
  box-shadow: var(--component-shadow);
  height: calc(100vh - 130px);

  @media only screen and (max-width: 1440px) {
    height: calc(100vh - 120px);
  }

  @media only screen and (max-width: 867px) {
    padding: 10px;
    height: calc(100vh - 110px);
  }

  &__iframe {
    height: 100%;
  }
}
