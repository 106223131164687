.map {
  & .chart-component {
    display: flex;
    justify-content: center;
  }

  &__tabs {
    list-style-type: none;
    display: flex;
    margin: 0;

    @media only screen and (max-width: 1120px) {
      padding-left: 20px;
      font-size: 14px;
    }

    @media only screen and (max-width: 867px) {
      font-size: 13px;
    }
  }

  &__tab {
    margin: 0;
    align-self: stretch;
  }

  &__btn {
    padding: 10px 20px;
    border: none;
    border-radius: var(--component-radius-secondary) var(--component-radius-secondary) 0 0;
    margin-right: 5px;
    height: 100%;

    @media only screen and (max-width: 867px) {
      padding: 8px 15px;
    }

    &.active {
      background: var(--white);
      position: relative;
      z-index: 5;
    }
  }

  &__image {
    width: 80%;
    cursor: pointer;

    @media only screen and (max-width: 867px) {
      width: 100%;
    }

    &:hover + .tooltip-window {
      display: block;
      font-size: 12px;
    }
  }
}
