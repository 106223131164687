.navigation {
  position: fixed;
  z-index: 3;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 50px;
  box-sizing: border-box;

  @media only screen and (max-width: 1440px) {
    padding: 15px 40px;
  }

  @media only screen and (max-width: 867px) {
    padding: 10px 15px;
  }

  &__menu {
    margin: 0;
    position: relative;
    z-index: 1;

    & ul {
      list-style-type: none;
    }
  }

  &__links {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;

    & li {
      position: relative;
    }
  }

  &__link {
    text-decoration: none;
    margin-right: 50px;
    color: var(--white);
    display: flex;
    padding: 10px;
    transition: 400ms;
    border-radius: var(--component-radius-secondary);
    border: 2px solid transparent;

    @media only screen and (max-width: 1440px) {
      margin-right: 30px;
    }

    @media only screen and (max-width: 867px) {
      margin-right: 10px;
      padding: 5px 7px;
      font-size: 14px;
    }

    li:last-of-type & {
      margin-right: 0;
    }

    &.active {
      border-color: var(--white);
    }
  }

  &__arrow {
    width: 7px;
    margin-left: 10px;
    transform: rotate(90deg);
    transition: transform 0.3s ease;

    .navigation__link:hover & {
      animation: moveDownUp 0.5s ease forwards;
    }

    &.rotated {
      transform: rotate(270deg);

      .navigation__link:hover & {
        animation: moveDownUpRotated 0.5s ease forwards;
      }
    }
  }

  & .ps__rail-y {
    opacity: 1;
  }

  & .ps__rail-y:hover > .ps__thumb-y,
  & .ps__rail-y:focus > .ps__thumb-y,
  & .ps__rail-y.ps--clicking .ps__thumb-y {
    width: 8px;
    background-color: #79747e;
  }

  & .ps .ps__rail-y:hover,
  & .ps .ps__rail-y:focus,
  & .ps .ps__rail-y.ps--clicking {
    background: transparent;
  }
}

@keyframes moveDownUp {
  0% {
    transform: rotate(90deg) translateX(0);
  }
  50% {
    transform: rotate(90deg) translateX(5px);
  }
  100% {
    transform: rotate(90deg) translateX(0);
  }
}

@keyframes moveDownUpRotated {
  0% {
    transform: rotate(270deg) translateX(0);
  }
  50% {
    transform: rotate(270deg) translateX(5px);
  }
  100% {
    transform: rotate(270deg) translateX(0);
  }
}

.options {
  &-wrapper {
    background-color: var(--white);
    position: absolute;
    z-index: 2;
    top: 120%;
    left: -2px;
    margin: 0;
    border-radius: var(--component-radius-secondary);
    box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
    padding: 8px 0;
    height: 208px;
    max-width: calc(100vw - 20px);
    overflow: hidden;

    &.election {
      top: 90%;
    }

    @media only screen and (max-width: 867px) {
      height: 152px;
    }

    &.m {
      height: 112px;

      @media only screen and (max-width: 867px) {
        height: 84px;
      }
    }

    &.l {
      height: 160px;

      @media only screen and (max-width: 867px) {
        height: 118px;
      }
    }
  }

  &-list {
    list-style: none;
    padding: 0;
    margin-bottom: 0;

    & li {
      padding: 12px;
      cursor: pointer;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.5px;
      transition: all 0.3s ease;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #000;

      @media only screen and (max-width: 867px) {
        padding: 5px 8px;
        font-size: 14px;
      }

      &:hover {
        background-color: var(--lighter-grey);
      }
    }
  }
}

.user-icon {
  position: relative;
  z-index: 16;
  box-sizing: border-box;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 3px var(--lighter-grey) solid;
  color: var(--white);
  font-weight: 600;
  margin: 0;
  transition: 200ms;

  &.opened {
    border-color: var(--dark-grey);
    color: #000;
  }

  &:hover {
    cursor: pointer;
  }

  & .notify-num {
    position: absolute;
    box-sizing: border-box;
    text-align: center;
    top: -3px;
    right: -3px;
    padding: 1px 1px 0 2px;
    border-radius: 50%;
    background: var(--red-t5);
    width: 15px;
    height: 15px;
    font-size: 10px;
    color: #fff;
  }

  & img {
    opacity: 0.8;
  }
}

.outlet-wrap {
  position: relative;
  margin: 90px auto 0;
  height: calc(100vh - 90px);
  overflow: hidden;
  z-index: 2;

  @media only screen and (max-width: 1440px) {
    margin-top: 80px;
    height: calc(100vh - 80px);
  }

  @media only screen and (max-width: 867px) {
    margin-top: 70px;
    height: calc(100vh - 70px);
  }

  & > .scrollbar-container {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 0 50px;

    @media only screen and (max-width: 1996px) {
      padding: 0 25px;
    }

    @media only screen and (max-width: 1496px) {
      padding: 0 10px;
    }
  }

  & .ps__rail-y {
    opacity: 1;
  }

  & .ps__rail-y:hover > .ps__thumb-y,
  & .ps__rail-y:focus > .ps__thumb-y,
  & .ps__rail-y.ps--clicking .ps__thumb-y {
    width: 8px;
    background-color: var(--white);

    @media only screen and (max-width: 1496px) {
      width: 6px;
    }
  }

  & .ps .ps__rail-y:hover,
  & .ps .ps__rail-y:focus,
  & .ps .ps__rail-y.ps--clicking {
    background: transparent;
  }
}
