.top-posts:nth-of-type(2) {
  margin-left: 25px;

  @media only screen and (max-width: 1367px) {
    margin-left: 15px;
  }

  @media only screen and (max-width: 1120px) {
    margin: 15px 0 0;
  }

  & .top-posts-list {
    height: calc(100% - 86px);

    @media only screen and (max-width: 1120px) {
      height: 500px;
    }
  }
}
