.user {
  &-menu {
    position: absolute;
    right: 25px;
    top: 5px;
    width: 350px;
    z-index: 15;
    background: var(--white);
    box-shadow: var(--component-shadow-secondary);
    border-radius: var(--component-radius);

    @media only screen and (max-width: 867px) {
      top: 0;
      right: 10px;
    }

    @media only screen and (max-width: 450px) {
      width: 78vw;
    }

    .fixed & {
      right: 75px;
      top: 0;
    }
  }

  &-frame {
    position: relative;
    width: calc(100% - 20px);
    margin: 10px;
    border-radius: var(--component-radius);
    box-shadow: var(--component-shadow);
    padding: 21px 25px;
    box-sizing: border-box;
  }

  &name {
    font-size: 16px;
    color: var(--dark-grey);

    & span {
      background: var(--accent-grey);
      font-size: 9px;
      padding: 5px 10px;
      margin-left: 10px;
      border-radius: 5px;
      vertical-align: middle;
    }
  }

  &-image {
    position: relative;
    text-align: center;
    margin: 15px auto 10px;
    width: 100px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    border-radius: 50%;
    border: 3px var(--dark-grey) solid;
    font-size: 43px;
    font-weight: 900;
    color: var(--dark-grey);

    & img {
      width: 100px;
      height: 100px;
      border-radius: 50%;
    }
  }

  &-info {
    font-size: 12px;
    text-align: center;

    & span {
      font-size: 12px;
      text-align: center;
      display: block;
      width: 100%;
    }
  }
}

span {
  &.email {
    color: var(--dark-grey);
    padding-bottom: 3px;
  }

  &.org {
    font-size: 10px;
    color: var(--accent-grey);
  }
}

.menu {
  &-obj {
    position: relative;
    width: 96%;
    margin: 25px auto 0;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 7px 25px 5px;
    border-radius: var(--component-radius-secondary);
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
    border: 1px var(--light-grey) solid;
    transition: 400ms;

    &:hover {
      background: var(--lighter-grey);
      cursor: pointer;

      & .menu-label {
        color: #000;
      }
    }
  }

  &-icon {
    width: 25px;
    opacity: 0.5;
    margin: 0;
  }

  &-label {
    margin-top: -4px;
    padding-left: 15px;
    font-size: 14px;
    color: var(--dark-grey);
    transition: 400ms;
    margin: 0;
  }
}

.logout {
  position: relative;
  text-align: center;
  display: block;
  width: 100%;
  padding: 25px 0;

  & span {
    background: var(--red-t5);
    color: var(--white);
    font-size: 12px;
    padding: 10px 25px;
    border-radius: var(--component-radius);
    transition: 400ms;

    &:hover {
      background: var(--red-t4);
      cursor: pointer;
    }
  }
}
